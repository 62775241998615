import React, { useEffect, useState, useRef, useCallback } from "react";
import "../css/HotPicks.css";
import Tile from "./Tile"; // Use Tile if you want the "big" tile with stats

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const HotPicks = ({ isVisible, setIsVisible }) => {
  const [hotPicks, setHotPicks] = useState([]);
  const [selectedBet, setSelectedBet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [error, setError] = useState(null);

  const scrollContainerRef = useRef(null);
  const marqueeRef = useRef();

  // Fetch top-liked bets
  const fetchHotPicks = useCallback(async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/betting/top-liked-bets`);
      if (!response.ok) throw new Error("Failed to fetch hot picks");
      const data = await response.json();
      setHotPicks(data);
    } catch (err) {
      console.error("Error fetching hot picks:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchHotPicks();
  }, [fetchHotPicks]);

  // Marquee animation using requestAnimationFrame
  const animateMarquee = useCallback(() => {
    if (!scrollContainerRef.current || isHovered) return;
    const container = scrollContainerRef.current;
    container.scrollLeft += 5;
    if (container.scrollLeft >= container.scrollWidth / 2) {
      container.scrollLeft = 0;
    }
    marqueeRef.current = requestAnimationFrame(animateMarquee);
  }, [isHovered]);

  useEffect(() => {
    if (isVisible && hotPicks.length > 0 && !isHovered) {
      marqueeRef.current = requestAnimationFrame(animateMarquee);
    }
    return () => {
      if (marqueeRef.current) cancelAnimationFrame(marqueeRef.current);
    };
  }, [isVisible, hotPicks, isHovered, animateMarquee]);

  // Event handlers
  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);
  const handleTouchStart = useCallback(() => setIsHovered(true), []);
  const handleTouchEnd = useCallback(() => setIsHovered(false), []);
  const handleBetClick = useCallback((bet) => {
    setSelectedBet(bet);
  }, []);

  // Duplicate bets for infinite scrolling
  const repeatedBets = hotPicks.concat(hotPicks);

  // Utility to remove underscores from team names
  const removeUnderscores = useCallback(
    (teamName = "") => teamName.replace(/_/g, " "),
    []
  );

  if (!isVisible) return null;

  return (
    <div className="hot-picks-container">
      <div className="hot-picks-banner">
        <button className="close-btn" onClick={() => setIsVisible(false)}>
          ✖
        </button>

        <div
          className="hot-picks-scroll"
          ref={scrollContainerRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {loading && <div className="hot-pick-item">Loading bets...</div>}
          {error && <div className="hot-pick-item error">Error: {error}</div>}
          {!loading && !error && hotPicks.length > 0 ? (
            repeatedBets.map((bet, index) => {
              const homeTeam = removeUnderscores(bet.home_team);
              const awayTeam = removeUnderscores(bet.away_team);
              return (
                <div
                  key={index}
                  className="hot-pick-item"
                  onClick={() => handleBetClick(bet)}
                >
                  🔥 {homeTeam} vs {awayTeam} – Odds: {bet.odds}
                </div>
              );
            })
          ) : (
            !loading &&
            !error && (
              <div className="hot-pick-item no-bets">No Bets Available</div>
            )
          )}
        </div>
      </div>

      {selectedBet && (
        <div className="expanded-tile-container">
          <Tile
            data={{
              GameID: selectedBet.bet_id,
              HomeTeam: removeUnderscores(selectedBet.home_team),
              AwayTeam: removeUnderscores(selectedBet.away_team),
              Odds: selectedBet.odds,
              Bookie: selectedBet.bookie,
              Model_probability: selectedBet.model_probability,
              Implied_probability: selectedBet.implied_probability,
              StatLine: selectedBet.StatLine, 
              BetStatType: selectedBet.BetStatType,
              OverUnder: selectedBet.OverUnder,
              TeamID: removeUnderscores(selectedBet.home_team),
              Weather: "N/A",
              PlayerName: selectedBet.name,
              GameDate: selectedBet.game_date,
              bet_id: selectedBet.bet_id,
            }}
            selectedSport={selectedBet.sport || "AFL"}
            selectedBets={new Set()}
            setSelectedBets={() => {}}
            index={0}
            expandedIndex={0}
            onExpand={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default HotPicks;
